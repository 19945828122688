<template>
	<div class="other_page_r">
		<el-card class="card_box">
			<div slot="header" class="card_title">
				<span>用户管理/类别管理</span>
			</div>
			<div>
				<el-form :inline="true" :model="searchInfo" class="demo-form-inline">
					<el-form-item label="用户名称">
						<el-input
							class="custom_input_170"
							v-model="searchInfo.key"
							placeholder=""
						></el-input>
					</el-form-item>
					<el-form-item label="手机号">
						<el-input
							class="custom_input_170"
							v-model="searchInfo.mobile"
							placeholder=""
						></el-input>
					</el-form-item>
					<el-form-item label="邮箱">
						<el-input
							class="custom_input_170"
							v-model="searchInfo.email"
							placeholder=""
						></el-input>
					</el-form-item>
					<el-form-item label="认证状态">
						<el-select
							class="custom_input_170"
							v-model="searchInfo.type"
							placeholder=""
						>
							<el-option label="未验证" value="0"></el-option>
							<el-option label="已验证" value="1"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="用户ID">
						<el-input
							class="custom_input_170"
							v-model="searchInfo.user_id"
							placeholder=""
						></el-input>
					</el-form-item>
					<el-form-item label="用户类型">
						<el-select
							class="custom_input_170"
							v-model="searchInfo.user_type"
							placeholder=""
						>
							<el-option label="普通用户" value="1"></el-option>
							<el-option label="专栏用户" value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="账号状态">
						<el-select
							class="custom_input_170"
							v-model="searchInfo.state"
							placeholder=""
						>
							<el-option label="正常" value="1"></el-option>
							<el-option label="禁用" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="searchBtn">查询</el-button>
						<el-button type="primary" @click="reset">重置</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="table_box">
				<tablec :tableData="tableData"></tablec>
			</div>
			<div
				class="flex justify_content_end aligin_items_c table_footer custom_btn"
			>
				<div>
					<pagination
						v-show="total > 0"
						:total="total"
						:page.sync="searchInfo.page"
						:limit.sync="searchInfo.size"
						@pagination="getDataList"
					/>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import tablec from "./tablec.vue";
import { getUsersList } from "@/api/user";
import { searchMixin } from "@/utils/searchMixin.js";
export default {
	mixins: [searchMixin],
	components: { tablec },
	name: "",
	data() {
		return {
			tableData: [],
			total: Number,
			searchInfo: {
				page: 1,
				size: 10,
				key: "",
				type: "",
				user_id: "",
				user_type: "",
				state: "",
			},
		};
	},
	created() {
		this.getDataList();
	},
	methods: {
		searchBtn() {
			this.searchInfo.page = 1;
			this.getDataList();
		},
		getDataList() {
			getUsersList(this.searchInfo)
				.then((res) => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						console.log(this.tableData);
						this.total = res.data.total;
					} else {
						this.tableData = [];
						this.total = 0;
					}
					this.loading = false;
				})
				.catch((error) => {
					this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped></style>
